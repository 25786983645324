import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { IGenericResponse } from '../models/Schemas'
import { Observable, of , throwError} from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  authItem = 'x-udata-connect'
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  urlpath: string  = 'http://198.199.120.114:2300'
  //urlpath: string  = 'http://localhost:2300'

  constructor(private http: HttpClient) {
    
   }

   public setAuth(): boolean {
    if (localStorage.getItem(this.authItem) != null) {
      const udata = JSON.parse(localStorage.getItem(this.authItem));
      this.httpOptions.headers = this.httpOptions.headers.set('x-access-token', udata.token);
      return true
    }
    return false;
  }

  public post(resource: string, data: any, anonymous: boolean = false): Observable<IGenericResponse> {
    if(!anonymous){
      this.setAuth();
    }
    var path = `${this.urlpath}/${resource}`;
    return this.http.post(path, data, this.httpOptions).pipe(
      tap((_result: IGenericResponse) => {
        //console.log('Result in POST main:', _result);
      })
    )
  }

  public get(resource: string, anonymous: boolean = false): Observable<IGenericResponse> {
    if(!anonymous){
      this.setAuth();
    }
    var path = `${this.urlpath}/${resource}`;
    return this.http.get(path, this.httpOptions).pipe(
      tap((result: IGenericResponse) => {
        //console.log('Result in GET main:', result);
      })
    )
  }

}

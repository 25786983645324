import { Injectable } from '@angular/core';
import { CanActivate, 
  CanActivateChild, 
  CanDeactivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router 
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate, CanActivateChild {


  constructor(private accountSvc: AccountService, private router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {

  

    let qry = this.accountSvc.getCurrent()
    if(qry['success']!=null){
      console.log('NOT LOGGED')
      this.accountSvc.logout();
      return false;
    }else{
      qry['subscribe'](result=>{
        if(result!=null){
          //console.log('GUARD: ', result);
          return result.success;
        }
        return false;
      })
    } 
    return true;   
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {

    return true;
  }
  
}

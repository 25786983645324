import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit { 
  loginForm: FormGroup;
  constructor(private accountSvc: AccountService, private fb: FormBuilder, private router: Router){
    
  }

  ngOnInit():void{
    this.loginForm = this.fb.group({
      username: ['', [Validators.required]],
      password: ['',[Validators.required]]
    })
  }

  onLogin(){
    const values = this.loginForm.value;
    console.log(values)
    this.accountSvc.login(values.username, values.password)    
    .subscribe(result=>{
      //console.log('RESULT:',result)
      if(result.success){
        this.router.navigate(['/'])
      }
    }, error=>{
      console.log('Error: ', error)
    })
  }
}

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { IGenericResponse, INewUser, IUpdateUser } from '../models/Schemas'

import { Observable, of , throwError} from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


import { MainService } from './main.service'
@Injectable({
  providedIn: 'root'
})
export class AccountService extends MainService {

  

  constructor( http: HttpClient, private router: Router) {
    super(http);
   }

  

 

  getCurrent(): Observable<IGenericResponse> | IGenericResponse {
    if (this.setAuth()) {    
      return this.get('account/current').pipe(
        tap((result:IGenericResponse)=>{
          if (result.success) {
            localStorage.setItem(this.authItem, JSON.stringify(result.data));
          }
        })
      );
    } else {
      let response: IGenericResponse = {
        success: false,
        message: 'You are not logged'
      }
      return response;
    }
  }

  getAccount(id:string): Observable<IGenericResponse>{
    return this.get(`account/get/${id}`);
  }

  logout(): boolean{
    localStorage.removeItem(this.authItem)
    this.router.navigate(['/login']);
    return true;
  }

  create(user: INewUser): Observable<IGenericResponse> {
    return this.post('account/create', user);
  }

  update(user: IUpdateUser): Observable<IGenericResponse>{
    return this.post('account/update', user );
  }

  login(username: string, password: string): Observable<IGenericResponse> {
    var data = {
      username,
      password
    }

    //console.log('DATA: ', data)

    /*return this.http.post<IGenericResponse>(`${this.urlpath}/account/login`, data, this.httpOptions)
      
      .pipe(
        tap((result: IGenericResponse) => {
          if (result.success) {
            localStorage.setItem(this.authItem, JSON.stringify(result.data));
          }
        })
        //catchError( this.handleError)
      )*/

      return this.post('account/login', data, true)
      .pipe( tap((result: IGenericResponse)=>{
        if (result.success) {
          localStorage.setItem(this.authItem, JSON.stringify(result.data));
        }
      }))
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
      
  }

}

import {Component} from '@angular/core';
import { navItems } from '../../_nav';
import { AccountService } from '../../services/account.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;

  constructor(private acSvc: AccountService){

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  onLogout(){
    this.acSvc.logout();
  }
}
